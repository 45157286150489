/* YouTube API */
var tag = document.createElement('script');
tag.src = 'https://www.youtube.com/player_api';
var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
var videoPlayer, videoPlayer2, videoFull,
		playerDefaults = {loop: 1, autohide: 1, modestbranding: 0, rel: 0, showinfo: 0, controls: 0, disablekb: 1, enablejsapi: 1, iv_load_policy: 3};
function onYouTubePlayerAPIReady(){
  	videoPlayer = new YT.Player('videoPlayer', {
		events: {
			'onReady': app.onPlayer1Ready,
			'onStateChange': app.onPlayer1StateChange
		},
		playerVars: playerDefaults
  	});	  	
}

var app = {
	windowLoaded: function() {
		_main=this;				
	},
	windowResized: function() {
		_main=this;
		_main.getViewport();
	},
	windowScrolled: function() {
		_main=this;

	},	
	cookies: {		
		createCookie: function(name, value, days) {		    
		    _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
		    });
		},
		notification: function(state) {
			_main=app;
			switch (state) {
				case 'show':
					$('.footer').append('<div class="cookiesNotification"><p>'+_main.messages[_main.language].cookies+'</p><p class="closeNotification">OK &times;</p></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	vidRescale: function() {
		var w = $(window).width()+200;
    	var h = $(window).height()+200;
		if (w/h > 16/9){
			videoPlayer.setSize(w, w/16*9);
			$('.videoBg .screen').css({'left': '0px'});
		} else {
			videoPlayer.setSize(h/9*16, h);
			$('.videoBg .screen').css({'left': -($('.videoBg .screen').outerWidth()-w)/2});
		}
	},		
  	onPlayer1Ready: function(){
		(app.debug)?console.log('player 1 is ready'):'';
		if (_main.bodyId=='contact') {
			videoPlayer.loadVideoById({'videoId': _main.videoContact, 'suggestedQuality': 'hd720'});
		} else if (_main.bodyId=='form') {
			videoPlayer.loadVideoById({'videoId': _main.videoForm, 'suggestedQuality': 'hd720'});
		}
		
	},			
	onPlayer1StateChange: function(e) {
	  	_main=app;
	  	if (e.data === 1){
	  		app.currentVideoState='playing';
	    	$('.bg .placeholder').fadeOut();
	  	} else if (e.data === 2) {
	    	app.currentVideoState='paused';	  	    	
			videoPlayer.pauseVideo();	    	  	
	  	} else if (e.data === YT.PlayerState.ENDED) {
		    // app.currentVideoState='ended';
		    videoPlayer.playVideo();
		}
		(app.debug)?console.log('current PRIMARY video state: ',app.currentVideoState):'';
	},
	videoPlayer: function(state) {
    	_main=this;
    	switch (state) {
    		case 'show':
    			$('.videoContent').addClass('active');    			
    			if (_main.videoLaunched) {
    				videoPlayer.playVideo();	
    			} else {    				
    				videoPlayer.loadVideoById({'videoId': youtube, 'suggestedQuality': 'hd720'});	
    				_main.videoLaunched=true;
    			}
    			if (_main.viewport=='mobile' || _main.viewport=='tablet') {
    				$('.playVideo').hide();
    			}
    			$('body').addClass('noScroll');
    		break;
    		case 'toggle':
    			if (_main.videoLaunched==true) {
    				videoPlayer.pauseVideo();
    				_main.videoLaunched=false;
    			} else {
    				videoPlayer.playVideo();
    				_main.videoLaunched=true;
    			}
    		break;
    		case 'hide':
				$('.videoContent').removeClass('active');
				videoPlayer.pauseVideo();

				if (_main.viewport=='mobile' || _main.viewport=='tablet') {
    				$('.playVideo').show();
    			}
    			$('body').removeClass('noScroll');
    		break;
    	}
    },      	
	globalNavigation: function() {
		_main=this;
		console.log('nav: ',_main.globalNavState);
		if (_main.globalNavState) {
			$('section.content, footer').show();						
			$('nav').removeClass('opened');			
			$('.navTrigger .icon').toggle();
			// $('nav .navigation').slideToggle();
			$('nav .navigation').toggle();
			$('.logoArea a img').removeClass('inverted');
			$('.nav, .wrapper-menu').removeClass('open');			
			(_main.debug)?console.log('closing global nav'):'';
			_main.globalNavState=false;
		} else {
			$('section.content, footer').hide();
			$('nav').addClass('opened');			
			$('.navTrigger .icon').toggle();
			// $('nav .navigation').slideToggle();
			$('nav .navigation').toggle();
			$("html, body").animate({        
           		scrollTop: 0
        	}, 100, function() {
	        	$('.logoArea a img').addClass('inverted');
	        	$('.nav, .wrapper-menu').addClass('open');
				(_main.debug)?console.log('opening global nav'):'';
				_main.globalNavState=true;	
        	});			
		}
	},		
	loadingScreen: function(msg) {
		$('body').append('<figure class="popMessage">\
            <p>'+msg+'</p>\
        </figure>\
		<div class="overlay"></div> \
        ');
	},
	popMessage: function(msg) {
        _main=this;        
        // ($('.loadingScreen').length())?$('.loadingScreen').fadeOut():'';
        if ($('.popMessage').length) {
        	$('.popMessage, .overlay').remove();
        }
        $('body').append('<figure class="popMessage">\
            <p>'+msg+'</p>\
            <div class="closePop">&times;</div>\
        </figure>\
		<div class="overlay"></div> \
        ');
	},   
	handleSlider: function() {
		_main = this;
		_main.debug?console.log('initializing slider'):'';
		$('.heroSlider').slick({
			fade: true,
			speed: 800,
			swipe: false,
			autoplay: true,
			arrows: false,
			dots: true,
			pauseOnHover: false,
			pauseOnFocus: false
		});
	},
	lightbox: function() {
		_main=this;
		$('body').on('click', '.imgInteractive', function() {
			_main.debug?console.log('lightbox show'):'';
			$('main').append('<div class="lightboxPopup">\
			<img src="'+$(this).attr('src')+'" alt=""> \
			<div class="closePop">&times;</div> \
			</div>');
		});
		$('body').on('click', '.closePop', function() {
			_main.debug?console.log('lightbox hide'):'';
			$('.lightboxPopup').remove();
		});
	},
	events: function() {
		_main=this;
		$('.prevDef').on('click', function(e) {
			e.preventDefault();
		});

		$('body').on('click', '.playVideo', function() {
			_main.videoPlayer('show');
		});

		$('body').on('click', '.videoOverlay', function() {			
			_main.videoPlayer('toggle');
		});

		$('body').on('click', '.closeOverlay', function() {
			_main.videoPlayer('hide');
		});

		$('.navTrigger').on('click', function() {
			_main.globalNavigation();
		});

		$('.scrollDown').on('click', function() {			
			
			$('html, body').animate({
                scrollTop: $('.hero').outerHeight()
            }, 600);
		});

		// $('.langSwitch .main').on('click', function() {
		// 	$('.langSwitch a').addClass('show');
		// });
	},	
	fixParagraphs: function(target) {
		// $(target).each( function( index, element ){
		//     oldVal = $(this).html();
		// 	(_main.debug)?console.log('Before: ',oldVal):'';
		// 	newVal = oldVal
		// 		.replace(/\ o /g,' o&nbsp;')
		// 		.replace(/\ O /g,' O&nbsp;')
		// 		.replace(/\ i /g,' i&nbsp;')
		// 		.replace(/\ I /g,' I&nbsp;')
		// 		.replace(/\ z /g,' z&nbsp;')
		// 		.replace(/\ Z /g,' Z&nbsp;')
		// 		.replace(/\ u /g,' u&nbsp;')
		// 		.replace(/\ U /g,' U&nbsp;')
		// 		.replace(/\ w /g,' w&nbsp;')
		// 		.replace(/\ W /g,' W&nbsp;')
		// 		.replace(/\ a /g,' a&nbsp;')
		// 		.replace(/\ A /g,' A&nbsp;');		
		// 	(_main.debug)?console.log('After: ',newVal):'';
		// 	$(this).html(newVal);
		// });


		// oldVal = $(target).html();
		// (_main.debug)?console.log('Before: ',oldVal):'';
		// newVal = oldVal
		// 	.replace(/\ o /g,' o&nbsp;')
		// 	.replace(/\ O /g,' O&nbsp;')
		// 	.replace(/\ i /g,' i&nbsp;')
		// 	.replace(/\ I /g,' I&nbsp;')
		// 	.replace(/\ z /g,' z&nbsp;')
		// 	.replace(/\ Z /g,' Z&nbsp;')
		// 	.replace(/\ u /g,' u&nbsp;')
		// 	.replace(/\ U /g,' U&nbsp;')
		// 	.replace(/\ w /g,' w&nbsp;')
		// 	.replace(/\ W /g,' W&nbsp;')
		// 	.replace(/\ a /g,' a&nbsp;')
		// 	.replace(/\ A /g,' A&nbsp;');		
		// (_main.debug)?console.log('After: ',newVal):'';
		// $(target).html(newVal);
	},
	ga_event: function($action,$category,$label) {
        _main=this;        
        gtag('event', $action, {
		  'event_category' : $category,
		  'event_label' : $label
		});

    },
	getBodyId: function() {
		_main=this;
		_main.bodyId=$('body').attr('id');
		(_main.debug)?console.log('body id: ',_main.bodyId):'';

		(_main.bodyId=='home')?_main.handleSlider():'';
	},
	getViewport: function() {
		_main=this;
		wh=$(window).width();
		if (wh<=_main.breakpointMobile) {
			_main.viewport='mobile';
		} else if (wh>_main.breakpointMobile && wh<=_main.breakpointTablet) {
			_main.viewport='tablet';
		} else {
			_main.viewport='desktop';
		}
		(_main.debug)?console.log('viewport: ',_main.viewport):'';


	},
	loadScript: function(path) {
		_main=this;
		$.ajax({
		  	url: path,
		  	dataType: 'script',
		  	cache: true, // or get new, fresh copy on every page load
		  	success: function() {
		    	(_main.debug)?console.log('external script successfully loaded: ',path):'';
		  	},
	        error: function(msg) {
	            (_main.debug)?console.log('error while loading external script: ',msg):'';
	        }
		});
	},
	messages: {
		'pl': {
			'sendFormSuccess': 'Dziękujemy, wiadomość została wysłana.',
			'sendFormError': 'Wystąpił błąd podczas wysyłania wiadomości. Prosimy o sprawdzenie poprawności wprowadzonych danych i spróbowanie ponownie.',
			'youAreBot': 'Przepraszamy, ale nasz system nie był w stanie wykryć, czy jesteś botem, czy człowiekiem. Prosimy o kontakt mailowy lub telefoniczny.',
			'uknkown': 'Wystąpił nieprzewidziany błąd aplikacji',
			'noImages': 'Brak zdjęć.',
			'newsletterOk': 'Dziękujemy. Na podany adres zostanie wysłany link potwierdzający.',
			'newsletterError': 'Wystąpił błąd podczas dodawania tego adresu do bazy. Prosimy sprawdzić wpisany adres i spróbować ponownie.',
			'cookies': 'Na naszej stronie używamy plików cookies w celach statystycznych.',
			'ajaxError': 'Wystąpił błąd podczas połączenia z serwerem.',
			'pleaseWait': 'Proszę czekać...'
		},
		'en': {
			'sendFormSuccess': 'Thank you for your message.',
			'sendFormError': 'There was a problem while sending your message. Please verify data you have provided and try again.',
			'youAreBot': 'We are sorry but we couldn`t verify if you are human or bot. Please send us an e-mail message or contact via phone.',
			'uknkown': 'An unkown error occurred.',
			'noImages': 'No images found.',
			'newsletterOk': 'Thank you. Please check your inbox for confirmation message.',
			'newsletterError': 'There was a problem with that e-mail address. Please verify your address and try again.',
			'cookies': 'We are using cookies on our website.',
			'ajaxError': 'There is a problem with connection with server.',
			'pleaseWait': 'Please wait...'
		},
		'de': {
			'sendFormSuccess': 'Danke für deine Nachricht.',
			'sendFormError': 'There was a problem while sending your message. Please verify data you have provided and try again.',
			'youAreBot': 'We are sorry but we couldn`t verify if you are human or bot. Please send us an e-mail message or contact via phone.',
			'uknkown': 'Ein unbekannter Fehler ist aufgetreten.',
			'noImages': 'No images found.',
			'newsletterOk': 'Vielen Dank. Bitte checken Sie Ihren Posteingang auf Bestätigungsnachricht.',
			'newsletterError': 'Bei dieser E-Mail-Adresse ist ein Problem aufgetreten. Bitte überprüfen Sie Ihre Adresse und versuchen Sie es erneut.',
			'cookies': 'Wir verwenden Cookies auf unserer Website.',
			'ajaxError': 'Es besteht ein Problem mit der Verbindung zum Server.',
			'pleaseWait': 'Warten Sie mal...'
		}
	},
	init: function() {
		_main=this;
		_main.getViewport();
		_main.getBodyId();
		_main.events();
		_main.cookies.checkCookies();
		_main.lightbox();
	},

	debug: true,
	debugOutput: false,
	viewport: null,
	loadedGallery: false,
	cookieName: 'HartTech',
	visitorType: null,

	globalNavState: false,
	currentVideoState: null,
	bodyClass: null,
	bodyId: null,
	language: $('main').attr('data-lang'),
	initialized: false,

	breakpointMobile: 640,
	breakpointTablet: 1100,

}

$(document).ready(function() {
	app.init();	
});

$(window).on('load', function() {
	app.windowLoaded();
});

$(window).resize(function() {
	app.windowResized();
});

$(document).scroll(function() {
	app.windowScrolled();
});
